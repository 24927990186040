.contact-page{
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #f3f3f7;
    overflow: scroll;
}

.image-wrapper {
    width: 100%;
    height: 60%;
}

.main-container {
    width: 100%;
    padding-bottom: 32px;
}

.details-wrapper{
    width: 90%;
    font-size: 14px;
    color: #303030;
    margin-left: 50%; 
    transform: translateX(-50%);
    margin-top: 24px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 19px -26px rgba(0,0,0,0.1);
    overflow: hidden;
}

.heading-text {
    font-size: 12px;
    color: #303030;
}

.primary-text {
    font-size: 12px;
    color: #007AFF;
}

.details-list-item {
    padding: 8px ;
    border-bottom: 1px solid #d3d3d3;
    position: relative;
}

.details-list-item:nth-last-child(1){
    border: none;
}

.details-list-item:active .active-overlay{
    display: block;
}

.active-overlay{
    width: 120%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10%;
    background-color: #303030;
    opacity: 0.3;
    border-radius: 10px;
    display: none;
}