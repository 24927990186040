.toast-wrapper {
    padding: 8px 12px;
    border-radius: 16px;
    background-color: rgba(233, 227, 227, 0.45);
    /* background-color: rgba(51, 45, 45, 0.3); */
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    animation: toastslidein 0.25s;
    color: #303030;
    font-weight: bold;
}

.toast-remove{
    animation: toastslideout 0.5s;
}

@keyframes toastslidein {
    0%{
        transform: translate(-50%, -100%);
        opacity: 0.1;

    }
    100%{
        transform: translate(-50%, 0%);
        opacity: 1;
    }
}

@keyframes toastslideout {
    0%{
        transform: translate(-50%, 0%);

    }
    100%{
        transform: translate(-50%, -300%);
        opacity: 0;
    }
}