.list-wrapper{
    width: 100%;
    margin-top: 16px;
}

.list-title {
    color: #303030;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
}

.list {
    width: 100%;
    height: 195px;
    background-color: white;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    overflow: scroll;
}

.list-item {
    color: #303030;
    font-size: 12px;
    border-bottom: 1px solid #e5e5e5;
    width: 95%;
    margin-left: 5%;
    padding: 10px 0;
}