.slideup-page {
    width: 100%;
    height: 93%;
    background-color: #f7f7f3;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    border-radius: 10px;
    animation: slideup-animation 0.25s;
}

.slideup-header{
    width: 100%;
    height: 30px;
    padding: 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e1e1e1;
}

.slideup-header p {
    color: #303030;
}

.cancel-button {
    color: #0277b5;
    cursor: pointer;
    position: absolute;
    right: 10px;
}

.slideup-page-close{
    animation: slideup-close-animation 0.26s;
}

@keyframes slideup-animation {
    0%{
        bottom: -100%;
    }

    100%{
        bottom: 0;
    }
}

@keyframes slideup-close-animation {
    0%{
        bottom: 0%;
    }

    100%{
        bottom: -100%;
    }
}