.phone-dock{
    width: 300px;
    height: 85px;
    border-radius: 30px;
    position: absolute;
    bottom: 10px;
    background: rgba(255, 255, 255, 0.27);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* top: 50%; */
    left: 50%;
    transform: translateX(-50%);
}

.dock-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.linkedin-icon {
    background: #0277b5;
    overflow: hidden;
}

.name-capsule{
    position: absolute;
    bottom: 110%;
    background: rgba(58, 171, 204, 0.77);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    padding: 4px 22px;
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%);
    color: #f3f3f3;
    font-size: 14px;
}

