.action-sheet{
    width: 95%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    animation: modalShow 0.2s;
}

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #303030;
    opacity: 0.5;
    z-index: 5;
    overflow: hidden;
}

.action-sheet-list {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 10px;
}

.action-sheet-title{
    color: #a7a7a7;
    text-align: center;
    font-size: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #d3d3d3;
}

.action-sheet-list-item{
    padding: 12px 0;
    font-size: 16px;
    color: #007AFF;
    text-align: center;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
}

.action-sheet-list-item:nth-last-child(1){
    border: none;
}

.action-sheet-cancel {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 12px 0;
    font-size: 16px;
    color: #007AFF;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
}

@keyframes modalShow {
    0%{
        transform: translate(-50%, 100%);
    }
    100%{
        transform: translate(-50%, 0%);
    }
}